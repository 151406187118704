/* extra small devices */
@media only screen and (max-width: 600px) {
    @font-face {
        font-family: "Proxima Nova";
        font-weight: bold;
        font-style: normal;
        src: url("../../../assets/fonts/Proxima\ Nova\ Bold.otf");
    }

    .footer-container {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #007bff;
    }

    .footer-text {
        color: #ffffff;
        font-size: 3vw;
        height: 1vh;
        margin-top: -0.5vh;
        padding-bottom: 1vh;
    }
}

/* small devices */
@media only screen and (min-width: 600px) {
    @font-face {
        font-family: "Proxima Nova";
        font-weight: bold;
        font-style: normal;
        src: url("../../../assets/fonts/Proxima Nova Bold.otf");
    }

    .footer-container {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #007bff;
    }

    .footer-text {
        color: #ffffff;
        font: Medium 30px/40px Proxima Nova;
    }
}

/* medium portrait devices */
@media only screen and (min-width: 768px) and (orientation: portrait) {
    @font-face {
        font-family: "Proxima Nova";
        font-weight: bold;
        font-style: normal;
        src: url("../../../assets/fonts/Proxima Nova Bold.otf");
    }

    .footer-container {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #007bff;
    }

    .footer-text {
        color: #ffffff;
        font: Medium 30px/40px Proxima Nova;
    }
}

/* medium landscape devices */
@media only screen and (min-width: 768px) and (orientation: landscape) {
    @font-face {
        font-family: "Proxima Nova";
        font-weight: bold;
        font-style: normal;
        src: url("../../../assets/fonts/Proxima Nova Bold.otf");
    }

    .footer-container {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #007bff;
    }

    .footer-text {
        color: #ffffff;
        font: Medium 30px/40px Proxima Nova;
    }
}

/* large devices */
@media only screen and (min-width: 992px) {

}

/* extra large devices */
@media only screen and (min-width: 1200px) {
    @font-face {
        font-family: "Proxima Nova";
        font-weight: bold;
        font-style: normal;
        src: url("../../../assets/fonts/Proxima Nova Bold.otf");
    }

    .footer-container {
        width: 100%;
        height: 7vw;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #007bff;
        position: relative;
        bottom: 0;
    }

    .footer-text {
        color: #ffffff;
        font: bold 30px/40px Proxima Nova;
        font-size: 2.5vh;
    }
}

/*27 and up big screen macs */
@media only screen and (min-width: 4500px) and (max-width: 7000px) {
  .footer-text {
    color: #ffffff;
    font: bold 30px/40px Proxima Nova;
    font-size: 2.5vh;
    margin-bottom: 0vh;
  }
}
